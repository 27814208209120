import PublicIcon from "@mui/icons-material/Public";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { RenderLink } from "../utils";

const features = [
  {
    title: "Central Service Dashboard",
    description:
      "Here you can request new Trains and inspect the result of finished Trains.",
    label: "Visit the Train Requester",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_REQUESTER,
    infoLink: "/product-overview#central-service",
  },
  {
    title: "Metadata Dashboard",
    description:
      "Here you can retrieve metadata about different components of the PHT, e.g., a currently running execution.",
    label: "Visit Metadata Dashboard",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_MONITORING,
    infoLink: "/product-overview#pht-infrastructure",
  },
  {
    title: (
      <>
        Station
        <br />
        Registry
      </>
    ),
    description:
      "Here you can see all the registered Stations and onboard your own.",
    label: "Visit the Station Registry",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_STATION_REGISTRY,
    infoLink: "",
  },
  {
    title: "Train Creator",
    description: "The train creator allows you to build PHT Trains.",
    label: "Visit the Train Creator",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_TRAIN_CREATOR,
    infoLink: "/product-overview#train-creation-wizard",
  },
  {
    title: "Train Store",
    description: "The train store views all available PHT Trains.",
    label: "Visit the Train Store",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_TRAIN_STORE,
    infoLink: "/product-overview#train-creation-wizard",
  },
  {
    title: "Playground",
    description: "Use the playground to simulate and test your execution.",
    label: "Visit the Playground",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_PLAYGROUND,
    infoLink: "",
  },
  {
    title: "Schema",
    description: "Our metadata specification for the infrastructure.",
    label: "Visit the Schema",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_SCHEMA,
    infoLink: "",
  },
  {
    title: "Documentation",
    description: "Find detailed information about our components here.",
    label: "See the documentation",
    link: window._env_.REACT_APP_PUBLIC_CONSTANT_FEATURE_LINK_DOCUMENTATION,
    infoLink: "",
  },
];

const blogPosts = [
  {
    title: "PADME in the SMITH News-Blog",
    date: "November 15, 2022",
    author: "Sascha Welten",
    image: "./assets/logo-black.png",
    description: (
      <>
        Our PADME infrastructure has been presented in the SMITH News-Blog. More
        information can be found{" "}
        <RenderLink href="https://www.smith.care/en/2022/11/15/" text="here." />{" "}
      </>
    ),
  },
  {
    title: "Talk at miracum-difuture colloquium",
    date: "October 31, 2022",
    author: "Sascha Welten",
    image: "./assets/logo-black.png",
    description: (
      <>
        We presented the current status of the implementation of PADME at the
        miracum-difuture-colloquium. You can find information about the
        presentation{" "}
        <RenderLink
          href="https://sites.google.com/master-bids.de/miracum-difuture-kolloquium/miracum-difuture-kolloquium/2022/2022-10-25-implementierung-des-personal-health-train-im-smith-projekt"
          text="here."
        />{" "}
        Read here more about{" "}
        <RenderLink
          href="https://www.miracum.org/2022/10/18/miracum-beim-jahressymposium-der-medizininformatik-initiative"
          text="miracum"
        />{" "}
        and <RenderLink href="https://difuture.de" text="difuture" />.
      </>
    ),
  },
  {
    title: "New Homepage & Landing page Launch",
    date: "July 1, 2022",
    author: "Sascha Welten",
    image: "./assets/logo-black.png",
    description:
      "Our new online presence has been launched! On this facelifted landing page, you can find infos about PADME and get access to our services.",
  },
  {
    title: "Accepted paper for Methods of Information in Medicine journal",
    date: "January 17, 2022",
    author: "Sascha Welten",
    image: "./assets/blogs/books.jpeg",
    description:
      "Our paper on A Privacy-Preserving Distributed Analytics Platform for Health Care Data will be published in the Methods of Information in Medicine journal.",
  },
  {
    title: "Prof. Beyan at Digital Health Europe Summit",
    date: "October 13, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/event.jpeg",
    description:
      "Prof. Beyan attended a panel on Distributed Analytics at the Digital Health Europe Summit . During this panel, experts discussed solutions for on-demand access to federated datasets and to cloud computing resources, and how these have the potential to accelerate the digital transformation of health and care (Ref: https://digitalhealtheurope.eu/dhe-summit/).",
  },
  {
    title: "Exploring new Domains",
    date: "October 7, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/lightning.jpeg",
    description:
      "For the next 12 months, we are exploring the applicability of PADME in an other domain beyond healthcare. As part of the SFC “AI, Simulation and Data Science”, we are going to explore the applicability of PADME in early warning systems for floods. Connecting multiple decentralised datasets for flood predictions might improve their reliability. Let's see what we can do there.",
  },
  {
    title: "Station Registry online!",
    date: "September 3, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/Building.jpeg",
    description: (
      <>
        We are happy to announce that we successfully linked our PADME ecosystem
        with the Station Registry from our partners in Mittweida/Leipzig. Now,
        the Station on-boarding process is semi-automated and the corresponding
        overhead is reduced significantly. The Station Registry can be found
        here:{" "}
        <RenderLink
          href="https://station-registry.hs-mittweida.de"
          text="https://station-registry.hs-mittweida.de"
        />
      </>
    ),
  },
  {
    title: "Accepted paper at PDFL workshop 2021",
    date: "August 18, 2021",
    author: "Yongli Mou",
    image: "./assets/blogs/event.jpeg",
    description:
      "Our paper Optimized Federated Learning on Class-biased Distributed Data Sources is accepted for presentation at the PDFL workshop 2021 in conjunction with ECML PKDD. The paper will be published in a combined volume ECML PKDD Workshops.",
  },
  {
    title: "Accepted paper for Data Intelligence journal",
    date: "May 10, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/books.jpeg",
    description:
      "Our paper DAMS: A Distributed Analytics Metadata Schema will be published in the Data Intelligence journal.",
  },
  {
    title: "Accepted Paper at MIE21",
    date: "May 5, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/event.jpeg",
    description:
      "Our paper Distributed Skin Lesion Analysis across Decentralised Data Sources has been accepted for MIE21.",
  },
  {
    title: "Re-deploy of PADME Central Service was successfull",
    date: "April 30, 2021",
    author: "Sascha Welten",
    image: "./assets/blogs/Building.jpeg",
    description: (
      <>
        The growth of our infrastructure forced us to re-structure our central
        server. All services are now accessible via our new web-portal:{" "}
        <RenderLink
          href="https://menzel.informatik.rwth-aachen.de:3005"
          text="https://menzel.informatik.rwth-aachen.de:3005"
        />
        <br />
        <br />
        Stay tuned!
      </>
    ),
  },
];

const teams = [
  {
    type: "Team Cologne University",
    active: true,
    members: [
      {
        name: "Prof. Dr. Oya Beyan",
        role: "HEAD",
        designation: "Chair Holder @Köln, Group Leader @FIT",
        image: "./assets/team/oya.jpeg",
        social: [
          {
            type: "Website",
            link: "http://oyabeyan.info",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Mehrshad Jaberansary",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/mehrshad.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:pht@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Team FIT",
    active: true,
    members: [
      {
        name: "Yeliz Ucer Yediel",
        role: "Software Engineer M.Sc.",
        designation: "Product Management @FIT",
        image: "./assets/team/yeliz.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:yeliz.ucer.yediel@fit.fraunhofer.de",
            icon: <EmailIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/yelizucer",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Sven Weber",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/sven.jpeg",
        social: [
          {
            type: "LinkedIn",
            link: "https://de.linkedin.com/in/sven-weber-265736148",
            icon: <LinkedInIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Team Leipzig University",
    active: true,
    members: [
      {
        name: "Prof. Dr. Toralf Kirsten",
        role: "Contributor",
        designation: "Professor @Leipzig",
        image: "./assets/team/toralf.jpeg",
        social: [
          {
            type: "Email",
            link: "toralf.kirsten@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Masoud Abedi",
        role: "Machine & Deep Learning Methods",
        designation: "Ph.D Candidate @Leipzig",
        image: "./assets/team/masoud.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:masoud.abedi@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Maximilian Jugl",
        role: "Cyber Security",
        designation: "Ph.D Candidate @Leipzig",
        image: "./assets/team/maximilian.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:maximilian.jugl@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
      {
        name: "Lars Hempel",
        role: "Bioinformatics",
        designation: "Ph.D Candidate @Leipzig",
        image: "./assets/team/lars.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:lars.hempel@medizin.uni-leipzig.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://www.uniklinikum-leipzig.de/einrichtungen/medical-data-science",
            icon: <PublicIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Team RWTH University",
    active: true,
    members: [
      {
        name: "Prof. Dr. Stefan Decker",
        role: "Director",
        designation: "Chair Holder @RWTH, Director @FIT",
        image: "./assets/team/decker.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:stefan.decker@fit.fraunhofer.de",
            icon: <EmailIcon />,
          },
        ],
      },
      {
        name: "Sascha Welten",
        role: "Computer Science M.Sc.",
        designation: "PhD Student @RWTH",
        image: "./assets/team/sascha.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:welten@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://dbis.rwth-aachen.de/dbis/index.php/user/welten/",
            icon: <PublicIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/sascha-welten-040b32b2",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Yongli Mou",
        role: "Computer Science M.Sc.",
        designation: "PhD Student @RWTH",
        image: "./assets/team/yongli.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:welten@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
          {
            type: "Website",
            link: "https://dbis.rwth-aachen.de/dbis/index.php/user/mou/",
            icon: <PublicIcon />,
          },
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/mouyongli/040b32b2",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Laurenz Neumann",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/laurenz.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:pht@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
        ],
      },
      {
        name: "Muhammad Hamza Akhtar",
        role: "Student Assistant",
        designation: "Data Science @RWTH",
        image: "./assets/team/hamza.jpg",
        social: [
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/mhamzaakhtar/",
            icon: <LinkedInIcon />,
          },
        ],
      },
      {
        name: "Sabith Haneef",
        role: "Student Assistant",
        designation: "Software Systems Engineering @RWTH",
        image: "./assets/team/sabith.jpg",
        social: [
          {
            type: "LinkedIn",
            link: "https://www.linkedin.com/in/sabith-t-h-21a29716b/",
            icon: <LinkedInIcon />,
          },
        ],
      },
    ],
  },
  {
    type: "Alumni",
    active: false,
    members: [
      {
        name: "Hauke Heidemeyer",
        role: "Student Assistant",
        designation: "Computer Science @RWTH",
        image: "./assets/team/heidemeyer.jpeg",
        social: [
          {
            type: "Email",
            link: "mailto:pht@dbis.rwth-aachen.de",
            icon: <EmailIcon />,
          },
        ],
      },
    ],
  },
];

export { blogPosts, teams, features };
